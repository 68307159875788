<template>
  <div class="middlebody" v-if="model.PaymentPlans.length">
    <div class="cardHead text-center">
      <div class="mainTitle">{{ model.Title }}</div>
      <div class="PackageDesc">
        <div>
          <h3>
            <span v-if="getMinValue.Price.Format">{{ getMinValue.Price.Format.slice(0, -3) }} </span><small class="d-block">{{ getMinValue.Title }}</small
            ><small> {{ getMinValue.BillingPeriod.Frequency }} {{ getMinValue.BillingPeriod.Period.Name }}</small>
          </h3>
        </div>
      </div>
    </div>
    <div v-if="model.Features.length" class="mainTitle">Features</div>
    <ul v-if="model.Features.length">
      <li class="cartFeatures" v-for="(feature, index) in model.Features" :key="index" :disabled="!feature.IsFeatureAllowed">
        <h4>{{ feature.Name }}</h4>
        <p v-if="feature.Description">{{ feature.Description }}</p>
      </li>
    </ul>
    <div :class="model.Features.length ? 'cardFooter' : ''">
      <div class="radioOptinos">
        <!-- v-if="courseDetail.Packages.length > 1 && model.PaymentPlans.length > 1" -->
        <div class="mainTitle">Payment Plans</div>
        <div class="d-flex flex-column">
          <div class="paymentplanoption d-flex justify-content-between" v-for="element in model.PaymentPlans" :key="element.SOSPaymentPlanId" :class="selectedSOS($decryptedRouteParams.sosId) && selectedSOS($decryptedRouteParams.sosId).SOSPaymentPlanId === element.SOSPaymentPlanId ? 'font-weight-bold' : ''">
            <span class="titleWrap">
              {{ element.Title }}
              <div class="package-duration">({{ element.BillingPeriod.Frequency }} {{ element.BillingPeriod.Period.Name }})</div> </span
            ><strong v-if="element.Price.Format"
              >{{ element.Price.Format.slice(0, -3) }} <s v-if="element.PriceStrikethrough.Format" class="d-block strikethrough">{{ element.PriceStrikethrough.Format.slice(0, -3) }}</s>
            </strong>
          </div>
        </div>
      </div>
    </div>
    <b-modal centered text :id="`${selected}confirmCartModal1`" title="" hide-footer hide-header-close no-close-on-backdrop>
      <div class="text-center mtb30 cart-popup">
        <img src="/assets/images/banner/thumbs-up.svg" />
        <div class="modal-head modal-head-success">Do you want to change your plan?</div>
        <div class="button-bar-confirm">
          <button @click="yes()" class="btn secondary-btn">Yes</button>
          <button
            class="btn cart-btn"
            @click="
              $bvModal.hide(`${selected}confirmCartModal1`);
              no();
            "
          >
            No
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BFormRadioGroup } from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import { courseDetail as courseDetailStoreKeys, cart as cartStoreKeys, checkout as checkoutStoreKeys } from "../store/keys";
import cartConfirmationModal from "../views/course/cartConfirmationModal.vue";
export default {
  components: {
    BFormRadioGroup,
    cartConfirmationModal,
  },
  props: ["model", "defaultPaymentPlanId"],
  data() {
    return {
      selected: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.selected = this.defaultPaymentPlanId;
    });
  },
  watch: {
    defaultPaymentPlanId(newVal, oldVal) {
      if (newVal) {
        this.selected = newVal;
      }
    },
  },
  computed: {
    ...mapGetters(courseDetailStoreKeys.namespace, courseDetailStoreKeys.getters),
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
    getMinValue() {
      if (this.model && this.model.PaymentPlans.length) {
        return this.model.PaymentPlans.reduce((previous, current) => {
          return current.Price.Value < previous.Price.Value ? current : previous;
        });
      }
    },
  },
  methods: {
    ...mapActions(courseDetailStoreKeys.namespace, courseDetailStoreKeys.actions),
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    ...mapActions(checkoutStoreKeys.namespace, checkoutStoreKeys.actions),
    setSelectedPaymentPlan(event, element) {
      this.passSelectedPlantoParent({
        SOSPackageId: this.model.SOSPaymentPackageId,
        SOSPackageTitle: this.model.Title,
        paymentPlans: this.model.PaymentPlans,
        // SOSPaymentPlan: {
        //   id: event,
        //   title: element.Title,
        //   price: element.Price.Value,
        //   priceDisplay: element.Price.Format,
        //   priceStrikethrough: element.PriceStrikethrough.Value,
        //   priceStrikethroughDisplay: element.PriceStrikethrough.Format,
        // },
      });
      if (this.isPaymentPlanExist(this.$decryptedRouteParams.sosId)) {
        this.$bvModal.show(`${this.selected}confirmCartModal1`);
      }
    },
    passSelectedPlantoParent(object) {
      this.$emit("onPaymentPlanChange", object);
    },
    yes() {
      this.removeFromCart();
    },
    no() {
      let selectedCourse = this.GetSelectedSOSFromCart(this.$decryptedRouteParams.sosId);
      this.passSelectedPlantoParent({
        SOSPackageId: selectedCourse.SOSPaymentPackageId,
        SOSPackageTitle: selectedCourse.SOSPaymentPackageTitle,
        SOSPaymentPlan: {
          id: selectedCourse.SOSPaymentPlanId,
          title: selectedCourse.Title,
          price: selectedCourse.Amount,
          priceDisplay: selectedCourse.AmountDisplay,
          priceStrikethrough: selectedCourse.AmountStrikethrough,
          priceStrikethroughDisplay: selectedCourse.AmountStrikethroughDisplay,
        },
      });
    },
    removeFromCart() {
      this.remove_FromCart({
        data: this.$decryptedRouteParams.sosId,
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          this.$bvModal.hide(`${this.selected}confirmCartModal1`);
          this.checkout_cart();
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
  },
};
</script>
