<template>
  <div>
    <section class="paymentsection_desktop" v-if="courseDetail && courseDetail.Packages">
      <div class="container">
        <!-- Card Header -->
        <div class="paycardhead">
          <div class="row">
            <div class="col-lg-6 textArea">
              <!-- {{ model.Packages }} -->
              <h3 class="font-weight-bold">Choose a plan that fits your learning goals</h3>
            </div>
            <div class="col-lg-3" v-for="el in courseDetail.Packages" :key="el.SOSPaymentPackageId">
              <div class="cardtd text-center planTopHead"
                :class="[isCourseExist($decryptedRouteParams.sosId, el.SOSPaymentPackageId) ? 'selectedBox' : '', courseDetail.EnableTrial ? 'freetrail' : '']">
                <span class="mainTitle">{{ el.Title }}</span>
                <h4 v-if="el.PaymentPlans.length">
                  {{ getMinValue(el).Price.Format.slice(0, -3) }}
                  <small class="d-block font-weight-bold text-dark">{{ getMinValue(el).Title }}</small>
                  <small class="d-block">{{ getMinValue(el).BillingPeriod.Frequency }} {{
                    getMinValue(el).BillingPeriod.Period.Name }}</small>
                </h4>
                <!-- <button class="btn cart-btn"> Add To Cart </button> -->
              </div>
            </div>
            <!-- <div class="col-lg-3">
              <div class="cardtd text-center selectedBox">
                <span class="mainTitle">{{ this.model.Packages[1].Title }}</span>
                <h4>
                  <h4>
                    {{ getMinValue("1").Price.Format }} <small class="d-block">{{ getMinValue("1").Title }}</small>
                  </h4>
                </h4>
                
              </div>
            </div> -->
          </div>
        </div>
        <!-- Card Body -->

        <div class="row optionAvailability" v-for="(coursePackage, index) in courseDetail.Packages[0].Features"
          :key="index">
          <div class="col-lg-6 textArea">
            <span class="d-block">{{ coursePackage.Name }}</span>
            <p v-if="coursePackage.Description">
              {{ coursePackage.Description }}
            </p>
          </div>
          <div class="col-lg-3" v-for="el in courseDetail.Packages" :key="el.SOSPaymentPackageId">
            <div class="cardtd text-center"
              :class="[isCourseExist($decryptedRouteParams.sosId, el.SOSPaymentPackageId) ? 'selectedBox' : '', courseDetail.EnableTrial ? 'freetrail' : '']">
              <span v-if="el.Features.length" v-bind:class="cravings(el.Features[index].IsFeatureAllowed)"></span>
            </div>
          </div>
          <!-- <div class="col-lg-3">
            <div class="cardtd text-center">
              <span v-bind:class="cravings(model.Packages[1].Features[index].IsFeatureAllowed)"></span>
            </div>
          </div> -->
        </div>

        <!-- Card Footer -->
        <div class="paycardfoot">
          <div class="row optionAvailability">
            <div class="col-lg-6 textArea">
              <span class="d-block"></span>
              <p></p>
            </div>
            <div class="col-lg-3" v-for="el in courseDetail.Packages" :key="el.SOSPaymentPackageId">
              <div class="cardtd text-left"
                :class="[isCourseExist($decryptedRouteParams.sosId, el.SOSPaymentPackageId) ? 'selectedBox' : '', courseDetail.EnableTrial ? 'freetrail' : '']">
                <span class="pkgHeading d-block">Payment Plan:</span>
                <div class="planrow"
                  :class="selectedSOS($decryptedRouteParams.sosId) && selectedSOS($decryptedRouteParams.sosId).SOSPaymentPlanId === coursePackage.SOSPaymentPlanId ? 'selectedplan' : ''"
                  v-for="(coursePackage, index) in el.PaymentPlans" :key="index">
                  <span class="pkgPlan"
                    :class="selectedSOS($decryptedRouteParams.sosId) && selectedSOS($decryptedRouteParams.sosId).SOSPaymentPlanId === coursePackage.SOSPaymentPlanId ? 'font-weight-bold' : ''">{{
                      coursePackage.Title }}
                    <div class="package-duration">({{ coursePackage.BillingPeriod.Frequency }} {{
                      coursePackage.BillingPeriod.Period.Name }})</div>
                  </span>
                  <span class="pkgPrice" v-if="coursePackage.Price.Format">{{ coursePackage.Price.Format.slice(0, -3) }}
                    <small v-if="coursePackage.PriceStrikethrough.Format">{{
                      coursePackage.PriceStrikethrough.Format.slice(0, -3) }}</small></span>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-3">
              <div class="cardtd selectedBox">
                <span class="pkgHeading d-block">Payment Plan:</span>
                <div class="planrow" v-for="(coursePackage, index) in model.Packages[1].PaymentPlans" :key="index">
                  <span class="pkgPlan">{{ coursePackage.Title }}</span>
                  <span class="pkgPrice"
                    >{{ coursePackage.Price.Format }} <small class="d-block">{{ coursePackage.PriceStrikethrough.Format }}</small></span
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="col-lg-6 textArea"></div>
            <div class="col-lg-3" v-for="el in courseDetail.Packages" :key="el.SOSPaymentPackageId">
              <div class="cardtd text-center planbottomFoot"
                :class="[isCourseExist($decryptedRouteParams.sosId, el.SOSPaymentPackageId) ? 'selectedBox' : '', courseDetail.EnableTrial ? 'freetrail' : '']">
                <button @click="AddToTrial(el)" class="btn cart-btn f-trail" v-if="courseDetail.EnableTrial">Free
                  Trial</button>
                <!-- v-if="courseDetail.EnableTrial" -->
                <div class="btn-loader d-flex justify-content-center mt10" v-if="isServer === 'server'">
                  <span class="skeleton-loader"></span>
                </div>
                <div v-else-if="!isCourseExist($decryptedRouteParams.sosId, el.SOSPaymentPackageId)">
                  <button @click="AddToCart(el)" class="btn secondary-btn">Add To Cart</button>
                </div>
                <div v-else>
                  <button class="btn cart-btn" @click="goToCart">
                    <i class="fa fa-check-circle mr-1"></i>
                    Go to cart
                  </button>
                  <div class="changePlan" v-if="el.PaymentPlans.length > 1" @click="AddToCart(el)">Change Plan</div>
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-3">
              <div class="cardtd text-center selectedBox">
                <button class="btn cart-btn f-trail">Free Trial</button>
                <button class="btn cart-btn">Choose Plan</button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { courseDetail as courseDetailStoreKeys, course as courseStoreKeys, checkout as checkoutStoreKeys, cart as cartStoreKeys } from "../store/keys";
import { appConstants } from "../common/constants";
export default {
  // props: ["model"],
  data() {
    return {
      isServer: process.env.VUE_ENV,
    };
  },
  computed: {
    ...mapGetters(courseDetailStoreKeys.namespace, courseDetailStoreKeys.getters),
    ...mapGetters(cartStoreKeys.namespace, cartStoreKeys.getters),
  },
  mounted() { },
  methods: {
    ...mapActions(cartStoreKeys.namespace, cartStoreKeys.actions),
    async AddToCart(obj) {
      this.$emit("choosePlan", obj, appConstants.enums.PaymentOption.PaymentPlan);
    },
    goToCart() {
      this.$router.push({ name: "cart" }).catch(() => { });
    },
    cravings(feature) {
      if (feature == true) {
        return "availability";
      } else {
        return "availability nodata";
      }
    },
    getMinValue(paymentPackage) {
      if (paymentPackage) {
        return paymentPackage.PaymentPlans.reduce((previous, current) => {
          return current.Price.Value < previous.Price.Value ? current : previous;
        });
      }
    },
    AddToTrial(coursePackage) {
      this.$emit("choosePlan", coursePackage, appConstants.enums.PaymentOption.Trial);
    },
  },
};
</script>
